import React from 'react'
import "./index.scss"
import Button from '../Button'

const Price = () => {
    return (
        <div id='price' className='price'>
            <div className='price__wrapper'>
                <p className='price__title'>Стоимость услуг</p>
                <div className='price__cards'>
                    <div className='price__card'>
                        <p className='price__card-cost'>
                            <span className='price__card-prev-cost'>6000</span>
                            4500 руб
                        </p>
                        <p className='price__card-text'>
                            Лицензия ФГИС Такси и внесение в Реестр перевозчиков
                        </p>
                    </div>
                    <div className='price__card'>
                        <p className='price__card-cost'>
                            <span className='price__card-prev-cost'>4000</span>
                            2500 руб <span>в месяц</span>
                        </p>
                        <p className='price__card-text'>
                            Подписка на предоставление мед.справок и путевых листов
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Price